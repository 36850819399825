import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Props as StripeProps, Stripe } from './Stripe'

export interface Props {
  stripes: StripeProps[]
}

export const ZigZag = memo(function ZigZag({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      {stripes.map((item, index) => (
        <Stripe key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section``
