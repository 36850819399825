import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Stripe = memo(function Stripe({
  cta,
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container row wrap stretch>
      <LeftSide dial={4}>
        <Wrapper>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <Button variant="S" {...cta} />
            </FadeInUp>
          ) : null}
        </Wrapper>
      </LeftSide>
      <RightSide>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &:before {
    content: '';
    border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.3;
    position: absolute;
    top: 2.5vw;
    right: 2.5vw;
    bottom: 2.5vw;
    left: 2.5vw;
    z-index: 2;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
    }
  }

  @media (max-width: 1023px) {
    &:before {
      display: none;
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding: 7.0625rem 8.056vw;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 3.75rem 1.9375rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-bottom: 1.0625rem;
  padding-left: 1rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 0.25rem;
    left: 0;
    transform: rotate(45deg);
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.25rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 2.625rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 42%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 74%;
  }
`
